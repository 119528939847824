<template>
  <div>
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--账户状态-->
          <div class="conditions">
            <div>账户状态：</div>
            <el-select
              class="ipt-default"
              style="width:100px"
              v-model="advancedForm.user_state"
              clearable
            >
              <el-option value label="全部"></el-option>
              <el-option value="0" label="已启用"></el-option>
              <el-option value="-1" label="已禁用"></el-option>
            </el-select>
          </div>
          <!--关键字-->
          <div class="conditions">
            <div>关键字选择：</div>
            <el-select class="ipt-default" v-model="advancedForm.keyword_type" clearable>
              <el-option value="shop_no" label="商户编号"></el-option>
              <el-option value="shop_name" label="商户名称"></el-option>
              <el-option value="name" label="联系人姓名"></el-option>
              <el-option value="mobile" label="联系人手机号查询"></el-option>
            </el-select>
            <el-input
              class="ipt-default"
              style="width: 200px"
              v-model="advancedForm.keyword"
              placeholder="请输入关键字进行搜索"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="clerk_name" label="联系人姓名" width="180" />
        <el-table-column prop="mobile" label="联系人手机号" width="180" />

        <el-table-column prop="shop_no" label="商户编号" width="180" />
        <el-table-column prop="shop_name" label="商户名称" width="180" />
        <el-table-column prop="link_weixin" label="微信号" width="180" />
        <el-table-column prop="email" label="电子邮箱" width="180" />
        <el-table-column label="创建日期" width="180">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="上次登录时间" width="180">
          <template slot-scope="scope">{{ scope.row.last_login===null?'':scope.row.last_login}}</template>
        </el-table-column>

        <el-table-column label="状态" width="180">
          <template slot-scope="scope">{{ scope.row.user_state===0?'已启用':'已禁用' }}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleDelete(scope.row)"
            >{{scope.row.user_state===0?'禁用':'启用'}}</el-button>
            <el-button size="mini" type="text" @click="handleEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <!--编辑操作 dialog-->
    <el-dialog
      title="编辑操作"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="editForm" :rules="MessageVisibleRules" ref="editForm" label-width="100px">
        <!--用户名称-->
        <el-form-item label="用户名称" prop="clerk_name">
          <el-input v-model="editForm.clerk_name"></el-input>
        </el-form-item>
        <!--用户手机号-->
        <el-form-item label="用户手机号" prop="mobile">
          <el-input v-model="editForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";
import * as API_Shop from "@/api/shop";
import { RegExp } from "@/libs/common/utils";
export default {
  name: "memberRecycle",

  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },

      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm: {
        user_state: "", // 账户状态
        keyword_type: "", // 根据关键字
        keyword: "", // 根据关键字搜索
      },

      /** 编辑数据 */
      dialogVisible: false,
      editForm: {},
      MessageVisibleRules: {
        clerk_name: [
          { required: true, message: "请填写用户名称", trigger: "blur" },
          // { min: 1, max: 5, message: "长度在 1 到 5 个字符", trigger: "blur" }
        ],
        mobile: [
          this.MixinRequired("请填写用户手机号"),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机格式有误！"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  // filters: {
  //   secrecyMobile(mobile) {
  //     mobile = String(mobile);
  //     if (!/\d{11}/.test(mobile)) {
  //       return mobile;
  //     }
  //     return mobile;
  //   }
  // },
  mounted() {
    this.GET_List();
  },
  methods: {
    /** 编辑保存 */
    submitForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = this.MixinClone(this.editForm);
          API_Member.shopAdminMsg(params.clerk_id, {
            uname: params.clerk_name,
            mobile: params.mobile,
          })
            .then((res) => {
              this.loading = false;
              this.$message.success("修改成功！");
              this.dialogVisible = false;
              this.GET_List();
            })
            .catch((_) => {
              this.loading = false;
            });
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },
    /** 启用禁用 */
    handleDelete(row) {
      const params = {
        clerk_id: row.clerk_id,
        user_state: row.user_state === 0 ? "-1" : "0",
      };
      this.$confirm(
        `确认要${row.user_state === 0 ? "禁用" : "启用"}该商户管理员吗？`,
        "提示",
        { type: "warning" }
      )
        .then(() => {
          API_Shop.disableShopAdmin(params).then(() => {
            this.$message.success("操作成功！");
            this.GET_List();
          });
        })
        .catch(() => {});
    },
    /** 编辑 */
    handleEdit(row) {
      this.editForm = this.MixinClone(row);
      this.dialogVisible = true;
    },
    /** 筛选 */
    searchEvent() {
      delete this.params["shop_no"];
      delete this.params["shop_name"];
      delete this.params["name"];
      delete this.params["mobile"];
      this.params.page_no = 1;
      this.params[this.advancedForm.keyword_type] = this.advancedForm.keyword;
      this.params.user_state = this.advancedForm.user_state;
      this.GET_List();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },
    /** 获取列表 */
    GET_List() {
      API_Shop.getShopAdmin(this.params)
        .then((res) => {
          res.data.forEach(item => {
            if (item.last_login !== null) {
              item.last_login = this.MixinUnixToDate("", "", item.last_login);
            }
          });
          this.tableData = res;
        })
        .catch({});
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
</style>
